import React from 'react'
import abimg from '../../images/image-7.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';


const Curriculum = (props) => {

    return(
        <section id="curriculum" className="tp-about-section ">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-12 col-12">
                    <img src={abimg} alt="" className=''/>

                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-text">
                        {/* <div className="tp-about-icon">
                            <i className="fi flaticon-web-design"></i>
                        </div> */}
                        <div className="tp-about-icon-content">
                        <div className='section-title section-title2'>
                        <span>Sample - Lesson 17</span>
                        <h2 className='mt-0'>Probing Questions</h2></div>
                        <ul className=' pl-5 text-light'>
                                <li className="mb-3">What was Malcolm's initial reaction when he saw himself with a conk?</li>
                                 <li className="mb-3">Why did Malcolm later say that the conk was “my first really big step toward self degradation”?</li>
                                 <li className="mb-3">What does Malcolm mean when he says “It makes you wonder if the Negro has completely lost his sense of identity, lost touch with himself.”</li>
                                 <li className="mb-3">Why does Malcolm “salute” Lionel Hampton and Sidney Poiter?</li>
                                 <li className="mb-3">How does Malcoln view a black woman who’s black boyfriend has a conk? </li>
                                 <li className="mb-3">Explain the symbolism in this scene. Why was the can of lye labeled as “Red Devil”?    </li>
                            </ul>
                    </div>
                    </div>
                    </div>
                   
                    </div>
                    </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default Curriculum;