import React from 'react'
import abimg from '../../images/image-2.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'


const About = (props) => {

    return(
        <section id="about" className="tp-about-section border-0">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={abimg} alt=""/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="tp-about-text">
                        {/* <div className="tp-about-icon">
                            <i className="fi flaticon-web-design"></i>
                        </div> */}
                        <div className="tp-about-icon-content">
                            <h2>Malcolm X Visionary</h2>

                            <div className='row p-3 '>
                                <p >We aim to uplift the legacy of Malcolm X for future generations through an engaging curriculum that draws on his autobiography, one of the most widely read non-fiction publications of the 20th century. Malcolm assumed many roles throughout his storied journey, and this curriculum aims to highlight his unmatched stature as a public intellectual who presented solutions to the foremost problems of our day and age. </p>
                                <p >Through this robust educational endeavor, we deconstruct the anomaly of his genius to understand the circumstances that created him. </p>
                            </div>

                            {/* <p><b>Malcolm X</b> (born <b>Malcolm Little</b>, later <b>Malik el-Shabazz;</b> May 19, 1925 – February 21, 1965) was an African-American Muslim minister and human rights activits who was a prominent figure during the civil rights movement. A spokesman for the Nation of islam until 1964, he was a vocal advocate for Black empowerment and the promotion of Islam within the Black community. A posthumous autobiography, on which he collaborated with Alex Hailey, was published in 1965.</p> */}
                            {/* <div className="signeture">
                                <span><img src={sign} alt=""/></span>
                                <p>African-American Muslim minister and Human rights Activist</p>
                            </div> */}
                            {/* <DefaultModal buttonClass={'template-btn'}/> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default About;