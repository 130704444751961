import React, {Fragment} from 'react';
import Hero from '../../components/Hero';
import About from '../../components/about';
import Service from '../../components/Service';
import Goals from '../../components/Goals';
import Infrastructure from '../../components/Infrastructure';
import PricingPlan from '../../components/Pricing';
import BlogSection from '../../components/BlogSection';
import ContactSection from '../../components/ContactSection';
import Footer from '../../components/Footer';
import Testimonial from '../../components/Testimonials';
import Portfolio from '../../components/portfolio';
import Scrollbar from '../../components/Scroolbar'
import Navbar from '../../components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Element} from 'react-scroll'
import LearnerInfra from '../../components/Learner';
import Curriculum from '../../components/Curriculum';
import Design from '../../components/Design';
import Team from '../../components/Team';
import Faqs from '../../components/FAQs';
import Aim from '../../components/aim';
import Founder from '../../components/founder';
import Instructional from '../../components/Instructional';
import Quotes from '../../components/quotes';
import Instruction from '../../components/Instruction';


const HomePage =() => {
    return(
        <Fragment>
            <Navbar />
            <Element name='home'>
                <Hero/>
            </Element>
            
            <Element name='about'>
                 <About/>
            </Element>
            <Element name='aim'>
                 <Aim/>
            </Element>
            <Element name="goals">
                <Goals/>
            </Element>
            
            <Element name="infrastructure">
                <Infrastructure/>
            </Element>

            <Element name="design">
                <Design/>
            </Element>

            <Element name="founder">
                <Founder/>
            </Element> 

            <Element name="instruction">
                <Instruction/>
            </Element>


            {/* <Element name="service">
                <Service/>
            </Element> */}

            <Element name="instructional">
                <Instructional/>
            </Element>

            <Element name="learnerinfra">
                <LearnerInfra/>
            </Element>
            <Element name="curriculum">
                <Curriculum/>
            </Element>

            <Element name="quotes">
                <Quotes/>
            </Element>

            <Element name="team">
                <Team/>
            </Element>
           
            
            {/* <Element name="portfolio">
                <Portfolio/>
            </Element> */}
           {/* <Element name="testimonial">
                <Testimonial name="testimonial"/>
            </Element> */}
            {/* <Element name="faqs">
                <Faqs/>
            </Element> */}
            {/* <PricingPlan/> */}
            {/* <Element name="blog">
                <BlogSection/>
            </Element> */}
            <Element name="contact">
                <ContactSection/>
            </Element>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;


