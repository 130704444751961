import React from 'react'
import assadkoshulnew from '../../images/assad-koshul-new.png'
import ilyasahshabazz from '../../images/ilyasahshabazz.png'

import sign from '../../images/signature.png'
import ilyasahsign from '../../images/ilyasahsign.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';


const Founder = (props) => {

    return(
        <section id="curriculum" className="tp-about-section ">
            <div className="container">
            <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={assadkoshulnew} alt="" className=''/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                       
                        <div className="tp-about-icon-content">
                        <div className='section-title section-title2'>
                        <h2>Founder's Message</h2>

                            </div>

                        <p>
                        In developing this comprehensive curriculum, we attempt to instill in our participants an understanding of our indebtedness to the great historical figure of Malcolm X. Through a structured, scenario-centric approach rooted in active learning, we encourage students to reflect deeply on source material and envision their lives as a continuation of Malcolm’s legacy. 

                        </p>
                        <div className='signature'>
                            <img src={sign} alt="" className='signature mb-2'/>
                            <p>Founder</p>
                        </div>
                    </div>
                    </div>

                    {/* <div className="col-4 text-center">
                       
                       <div className="tp-about-icon-content">
                       <div className='section-title section-title2'>
                       <h2>Instructional Design </h2>

                           </div>

                       <p>
                       Our Instructional Design is unique and is designed for active learning not passive learning.  We utilize inquiry based learning and visual proverbs to maximize comprehension and contextualization. 
                       </p>
                     </div>
                   </div> */}
                  
            </div>
            <div className="row align-items-center mt-5">
            <div className="col-lg-7 col-md-6">
                       
                       <div className="tp-about-icon-content">
                       <div className='section-title section-title2'>
                       <h2>Founder's Message</h2>

                           </div>

                       <p>
                       I pursued a Master of Science in Education and Human Resource Development with the goal of creating resources to assist educators who want to incorporate my father's legacy into their curriculum. The Malcolm X Education Curriculum will build upon the 18-year legacy of the Shabazz Center, providing transformational tools to facilitate individual, communal, and societal healing and liberation. Education has the power to change the world. I am proud of our curriculum. Through primary source material, we instill in each student an appreciation of Malcolm X's service and contribution to society. We show how his ideas and principles remain relevant to character development and current human rights struggles. Learning the importance of self-love teaches us how to love and care for others. Through a structured, case-study approach rooted in active learning, we hope each student will not only see their life as a continuation of Malcolm's work and legacy but will also commit to creating a more just and equitable society.

                        </p>

                       <div className='signature'>
                           <img src={ilyasahsign} alt="" className='signature mb-2'/>
                           <p>Founder</p>
                       </div>
                   </div>
                   </div>

                    <div className="col-lg-5 col-md-6 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={ilyasahshabazz} alt="" className=''/>
                        </div>
                        </div>
                    </div>
                   
                    {/* <div className="col-4 text-center">
                       
                       <div className="tp-about-icon-content">
                       <div className='section-title section-title2'>
                       <h2>Instructional Design </h2>

                           </div>

                       <p>
                       Our Instructional Design is unique and is designed for active learning not passive learning.  We utilize inquiry based learning and visual proverbs to maximize comprehension and contextualization. 
                       </p>
                     </div>
                   </div> */}
                  
            </div>
            </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default Founder;