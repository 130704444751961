import React from 'react'
import abimg from '../../images/image-8.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';


const Instructional = (props) => {

   
    return(
        <section id="curriculum" className="tp-about-section ">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={abimg} alt="" className=''/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 col-12">
                        <div className="tp-about-text">
                        {/* <div className="tp-about-icon">
                            <i className="fi flaticon-web-design"></i>
                        </div> */}
                        <div className="tp-about-icon-content">
                        <div className='section-title section-title2'>
                        {/* <span>Curriculum</span> */}
                        <h2 className='mt-0'>WorkShops Include:</h2></div>
                        <ul className=' pl-5 h4 text-light'>
                                <li className="mb-3">Journey to Knowledge of Self</li>
                                <li className="mb-3">A Liberation of Consciousness</li>
                                <li className="mb-3">Towards the Life we Were Meant to Lead</li>
                                <li className="mb-3">Towards Justice and Peace</li>
                                <li className="mb-3">Unlocking the Human Potential of Heart and Mind</li>
                                <li className="mb-3">Management and Governance in a Multi-Cultural World </li>
                                <li className="mb-3">Particulars of Experience vs Universal Experience </li>
                                <li className="mb-3">Movement vs Organization Operating an Enterprise in the 21st Century</li>
                        </ul>
                    </div>
                    </div>
                    </div>
                   
                    </div>
                    </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default Instructional;