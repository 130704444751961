import React, { Component} from 'react';
import { Link} from 'react-scroll'
import Logo from '../../images/logo.png';
import Home_1 from '../../images/home/1.jpg';
import Home_2 from '../../images/home/2.jpg';
import Home_3 from '../../images/home/3.jpg';
import Home_4 from '../../images/home/4.jpg';
import Home_5 from '../../images/home/5.jpg';
import Home_6 from '../../images/home/6.jpg';
import Home_7 from '../../images/home/7.jpg';
import Home_8 from '../../images/home/8.jpg';
import Home_9 from '../../images/home/9.jpg';
import Home_10 from '../../images/home/10.jpg';
import Home_11 from '../../images/home/11.jpg';
import Home_12 from '../../images/home/12.jpg';
import Home_13 from '../../images/home/13.jpg';
import Home_14 from '../../images/home/14.jpg';

import {NavLink} from 'react-router-dom'

class Header extends Component {

    state = {
        isOpen: false,

    }
    
    render() {

        return(
            <header id="header" className="site-header header-style-1">
                <nav className="navigation navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <NavLink className="navbar-brand for-mobile" to="/home">
                                <img src={Logo} alt="" />
                            </NavLink>
                        </div>
                        <div id="navbar" className={ this.state.isOpen ? "navbar-collapse navigation-holder active" : "navbar-collapse navigation-holder"}>
                            <div className="navbar-header">
                                <NavLink className="navbar-brand w-50" to="/home">
                                    <img src={Logo} alt="" />
                                </NavLink>
                            </div>
                            <button className="close-navbar" onClick={() => this.setState({isOpen: false})}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <button className="close-navbar-2" onClick={() => this.setState({isOpen: false})}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                            <ul className="nav navbar-nav">
                                <li className="home">
                                    <Link activeClass="active" to="home" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_1} alt=""/>
                                    </Link>
                                </li>
                                <li className="about">
                                    <Link activeClass="active" to="about" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_2} alt=""/>
                                    </Link>
                                </li>
                                <li className="goals">
                                    <Link activeClass="active" to="aim" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_3} alt=""/>
                                    </Link>
                                </li>
                                <li className="infrastructure">
                                    <Link activeClass="active" to="goals" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_4} alt=""/>
                                    </Link>
                                </li>
                                <li className="team">
                                    <Link activeClass="active" to="infrastructure" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_5} alt=""/>
                                    </Link>
                                </li>
                                <li className="design">
                                    <Link activeClass="active" to="design" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_6} alt=""/>
                                    </Link>
                                </li>
                                <li className="founder">
                                    <Link activeClass="active" to="founder" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_7} alt=""/>
                                    </Link>
                                </li>
                                <li className="instruction">
                                    <Link activeClass="active" to="instruction" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_8} alt=""/>
                                    </Link>
                                </li>
                                <li className="instructional">
                                    <Link activeClass="active" to="instructional" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_9} alt=""/>
                                    </Link>
                                </li>
                                <li className="learnerinfra">
                                    <Link activeClass="active" to="learnerinfra" spy={true} smooth={true}  duration={500} >
                                        <img src={Home_10} alt=""/>
                                    </Link>
                                </li>
                                <li className="curriculum">
                                    <Link activeClass="active" to="curriculum" spy={true} smooth={true}  duration={500}>
                                        <img src={Home_11} alt=""/>
                                    </Link>
                                </li>
                                <li className="quotes">
                                    <Link activeClass="active" to="quotes" spy={true} smooth={true}  duration={500}>
                                        <img src={Home_12} alt=""/>
                                    </Link>
                                </li>
                                <li className="blog">
                                    <Link activeClass="active" to="team" spy={true} smooth={true}  duration={500}>
                                        <img src={Home_14} alt=""/>
                                    </Link>
                                </li>
                                <li className="contact">
                                    <Link activeClass="active" to="contact" spy={true} smooth={true}  duration={500}>
                                        <img src={Home_13} alt=""/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="menu-open-btn-holder">
                            <button className="menu-open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;