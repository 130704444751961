import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Slides from '../../images/banner-1.jpg';
import { Link} from 'react-scroll'
import DemoModal from '../Hero/Demo'



class Hero extends Component {
    render() {
        return(
            <section id="home" className="hero hero-slider-wrapper hero-style-1">
                <div className="hero-slider">
                    <div className="slide" style={{ backgroundImage: `url(${Slides})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-10 col-sm-12 slide-caption">
                                   
                                    <div className="slide-title mb-5 mt-5">
                                        {/* <h1 className="text-uppercase text-white">Welcome to</h1> */}
                                        <h1 className='text-white'>El-Hajj Malik El-Shabazz </h1>
                                        <h2 className='m-0'>Malcolm X</h2>
                                        {/* <h1 className="text-uppercase text-white">Revolutionary</h1> */}
                                    </div>
                                    <div className="mb-5">
                                        <h4 className='text-white'>The Legacy of Malcolm X : Transformational Tools + Insights</h4>
                                        {/* <h5 className='font-weight-normal mt-3 text-white'>Reclaim Malcolm X' Legacy</h5>
                                        <h5 className='font-weight-normal text-white'>Comprehensive Inquiry Based Curriculum</h5> */}
                                    </div>
                                    <div className="btns d-flex">
                                        {/* <a href="#" target='_blank' className="template-btn go-contact-area">Enroll now</a> */}
                                       
                                    <Link activeClass="active" id="contact-us" className="text-white template-btn go-contact-area" to="contact" spy={true} smooth={true}  duration={500} >
                                            
                                            Contact US</Link>

                                        {/* <AnchorLink href="#contact" className="template-btn go-contact-area">Enroll now</AnchorLink> */}
                                     <DemoModal  buttonClass={'text-white template-btn go-contact-area ml-md-5'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="social-links">
                    <div className="overlay"></div>
                    <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/malcolmxvisionary"><i className="fa fa-facebook"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/malcolmxvisionary/"><i className="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/malcolmxvision"><i className="fa fa-twitter"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/malcolmxvisionary/"><i className="fa fa-linkedin"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@malcolmxvisionary"><i className="fa fa-youtube"></i></a></li>
                    </ul>
                </div>
                <div className="white_svg ">
                    <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                        <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                    </svg>
                </div>
            </section>
        )
    }
}

export default Hero;