import React, { Component } from 'react';
// import React, { useState } from 'react';
// import { SMTPClient } from 'emailjs';
import emailjs from '@emailjs/browser';






class ContactForm extends Component {


    state = {
        name: '',
        email: '',
        subject: '',
        lastname: '',
        events: '',
        notes: '',
        error: {}
    }


    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = ''

        this.setState({
            [e.target.name]: e.target.value,
            error
        })
    }

    subimtHandler = (e) => {
        e.preventDefault();

        var templateParams = {
            to_name: 'mukhyyar react',
            message: 'Check this out! im testing it bro '
        };

        emailjs.sendForm('service_sc0rekl', 'template_hs9rjgr', e.target, '8VeLM3Y26IwGbKqBV')

        .then((result) => {
            console.log(result.text);
            document.getElementById('success-msg').append('Email Successfully Sent');
            this.setState({
                name: '',
                lastname: '',
                email: '',
                subject: '',
                events: '',
                notes: '',
                error: {},
            });

        }, (error) => {
            console.log(error.text);
        });

        const { name,
            email,
            subject,
            lastname,
            events,
            notes, error } = this.state;

        if (name === '') {
            error.name = "Please enter your name";
        }
        if (email === '') {
            error.email = "Please enter your email";
        }
        if (subject === '') {
            error.subject = "Please enter your subject";
        }
        if (lastname === '') {
            error.lastname = "Please enter your Lastname";
        }
        if (events === '') {
            error.events = "Select your event list";
        }
        if (notes === '') {
            error.notes = "Please enter your note";
        }


        if (error) {
            this.setState({
                error
            })
        }
        if (error.name === '' && error.email === '' && error.email === '' && error.lastname === '' && error.subject === '' && error.events === '' && error.notes === '') {

            this.setState({
                name: '',
                email: '',
                subject: '',
                events: '',
                notes: '',
                error: {},
                


            })

        }
       

    }

    render(){
        const { name,
            email,
            subject,
            lastname,
            notes,
            error } = this.state;

        return(
            <form onSubmit={this.subimtHandler} className="form">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name"/>
                            <p>{error.name ? error.name : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input value={lastname} onChange={this.changeHandler} type="text" name="lastname" placeholder="Lastname"/>
                            <p>{error.lastname ? error.lastname : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email"/>
                            <p>{error.email ? error.email : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="form-field">
                            <input onChange={this.changeHandler} value={subject} type="text" name="subject" placeholder="Subject"/>
                            <p>{error.subject ? error.subject : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-field">
                            <textarea    name="notes" onChange={this.changeHandler} value={notes} placeholder="Message"></textarea>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="form-submit">
                            <button type="submit" className="template-btn">Send Message</button>
                        </div>
                    </div>
                    <div id="success-msg" className="bg-success col-12 mt-3  success-message text-center text-light" ></div>
                </div>
            </form>
        )
    }

}
export default  ContactForm;