import React, { useState } from 'react';
import GoalsSingle from '../ServiceSingle'
import { Button } from '@material-ui/core'
import s1 from '../../images/service-single/web-design/img-1.jpg'
import s2 from '../../images/service-single/web-design/img-2.jpg'
import s3 from '../../images/service-single/web-design/img-3.jpg'

import d1 from '../../images/service-single/development/img-1.jpg'
import d2 from '../../images/service-single/development/img-2.jpg'
import d3 from '../../images/service-single/development/img-3.jpg'

import c1 from '../../images/service-single/creative/img-1.jpg'
import c2 from '../../images/service-single/creative/img-2.jpg'
import c3 from '../../images/service-single/creative/img-3.jpg'

import r1 from '../../images/service-single/responsive/img-1.jpg'
import r2 from '../../images/service-single/responsive/img-2.jpg'
import r3 from '../../images/service-single/responsive/img-3.jpg'

import b1 from '../../images/service-single/branding/img-1.jpg'
import b2 from '../../images/service-single/branding/img-2.jpg'
import b3 from '../../images/service-single/branding/img-3.jpg'

import sp1 from '../../images/service-single/support/img-1.jpg'
import sp2 from '../../images/service-single/support/img-2.jpg'
import sp3 from '../../images/service-single/support/img-3.jpg'

import icon_1 from '../../images/personal-development.png'
import icon_2 from '../../images/leadership.png'
import icon_3 from '../../images/overpopulation.png'
import icon_4 from '../../images/manager.png'
import icon_5 from '../../images/book.png'
import icon_6 from '../../images/thought.png'
import icon_7 from '../../images/link.png'


const Goals = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state,setState] = useState({
    })

    const handleClickOpen = (item) =>{
        setOpen(true);
        setState(item)
    }
    const goals = [
        {
            Id:"1",
            sIcon:icon_1,
            heading:"Reclaim El-Hajj Malik El-Shabazz  - Malcolm X’s Legacy",
            Simg1:s1,
            Simg2:s2,
            Simg3:s3,
            des:'Draw inspiration from Malcolm’s varied life experiences and internalize his unwavering commitment to liberation'
        },
        {
            Id:"2",
            sIcon:icon_2,
            heading:"Modern Day Principled Leader",
            Simg1:d1,
            Simg2:d2,
            Simg3:d3,
            des:'Understand how Malcolm practiced what he preached, even when it came at a detriment to his own safety and security'
            
        },
        {
            Id:"3",
            sIcon:icon_3,
            heading:"Leader Capable of Communicating + Impacting Cross Sections of Society",
            Simg1:c1,
            Simg2:c2,
            Simg3:c3,
            des:'Learn about Malcolm’s ability to communicate with disparate demographics, promoting an appeal to morality '
        },
        {
            Id:"4",
            sIcon:icon_4,
            heading:"Individual Ownership/Responsibility + Action",
            Simg1:r1,
            Simg2:r2,
            Simg3:r3,
            des:'Study how Malcolm treated his personal obligation as a priority; he remained loyal to his faith and community until the very end'
        },
        {
            Id:"5",
            sIcon:icon_5,
            heading:"Change Agent Spiritual, Intellectual, Social + Political Level",
            Simg1:b1,
            Simg2:b2,
            Simg3:b3,
            des:'Understand how Malcom was effective across all dimensions and how his voice resonated on a large scale'
        },
        {
            Id:"6",
            sIcon:icon_6,
            heading:"Power Of Broad-Based Experience + Thinking",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Extract meaning from Malcolm’s experience and apply it widely, rejecting local particularisms in favor of universal experience '
        },
        {
            Id:"7",
            sIcon:icon_7,
            heading:"Group Dynamics and Mobilization",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Study how Malcolm’s agency as an individual strived for collective upliftment; how he was able to not only mobilize but also organize movements for common goals '
        },
    ]

    return (
        <div id="goals" className="service-area section-padding">
            <div className="borderd"></div>
            <div className="container">
                <div className="col-l2">
                    <div className="section-title section-title2 text-center">
                        {/* <span>Curriculum</span> */}
                        <h2> Objectives Include:</h2>
                    </div>
                </div>
                <div className="row items-goals items-obj-incl justify-content-center">
                    {goals.map((serv, srv) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 items"  key={srv}>
                            <div className="service-section">
                                <div className="services-wrapper">
                                    <div className="services-icon-wrapper mb-4">
                                        <div className="service-dot">
                                            <div className="dots"></div>
                                            <div className="dots2"></div>
                                        </div>
                                        <img src={serv.sIcon}/>
                                        
                                    </div>
                                    <div className="service-content">
                                        <h3 className='text-white'>{serv.heading}</h3>
                                        <p>{serv.des}</p>
                                        {/* <Button
                                            className="btn"
                                            onClick={()=> handleClickOpen(serv)}>
                                            Read More
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                </div>
            </div>
            <div className="white_svg">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
            <GoalsSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/>
        </div>
    );
}
export default Goals;