import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialImg_1 from '../../images/testimonial/1.jpg';
import testimonialImg_2 from '../../images/testimonial/2.jpg';
import testimonialImg_3 from '../../images/testimonial/3.jpg';

class Testimonial extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div id="testimonial" className="testimonial-area">
                <div className="container">
                    <div className="col-l2">
                        <div className="section-title section-title2 text-center">
                            <span>Malcolm X</span>
                            <h2>Testimonials</h2>
                        </div>
                    </div>
                    <div className="testimonial-active">
                        <div className="testimonial-wrap">
                            <Slider {...settings}>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        {/* <div className="testimonial-img">
                                            <img src={testimonialImg_1} alt="" />
                                        </div> */}
                                        <div className="testimonial-content">
                                            <p>
                                                Assad Koshul has provided a valuable service to Muslim pedagogy with their systematic curriculum built around the MBC series that highlights the life of Umar bin al-Khattab. I have reviewed that detailed curriculum and find the deep analysis of each episode, focusing on management methods, leadership practices, group dynamics and much more makes this curriculum a source of great benefit for both students and teachers, laity and leaders alike. I highly recommend this groundbreaking curriculum and I am confident that it will become a mainstay in every home, school and masjid.                  </p>
                                            <p>
                                                <i class="font-weight-lighter">
                                                    Imam Zaid Shakir                    </i>
                                            </p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        {/* <div className="testimonial-img">
                                            <img src={testimonialImg_1} alt="" />
                                        </div> */}
                                        <div className="testimonial-content">
                                            <p>
                                                Assad Koshul has developed a comprehensive Islamic curriculum that pulls from our primary story. It teaches it using some state of the art in instructional methods, while remaining true to the objectives of the tradition. There is enough and diverse material here to benefit a person of any level.                  </p>
                                            <p>
                                                <i class="font-weight-lighter">
                                                    Muhammad S. Eissa, Ph.D                    </i>
                                            </p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        {/* <div className="testimonial-img">
                                            <img src={testimonialImg_1} alt="" />
                                        </div> */}
                                        <div className="testimonial-content">
                                            <p>
                                                Dear Brother Assad

                                                As Salaamu Alaikum wa Rahmatullah

                                                This is to thank you for our recent seven day retreat session. Personally I found it to be an inspirational and thought-provoking course of study .

                                                The comcensus of the Association of African American Imams is that it was a most worthy and valuable investment of time and money, and we are confident of its usefulness in relation to our responsibilities and mission as leaders.

                                                Further we appreciate your professionalism , passion, and obvious dedication to the cause of a reality-based uplift,ment of the Ummah in America, guided by the examples discernible from the seerah. May Allah reward your efforts.                  </p>
                                            <p>
                                                <i class="font-weight-lighter">
                                                    Al-Hajj Talib ‘Abdur-Rashid Imam. <br />The Mosque of Islamic Brotherhood Inc. Chairman, The Association of African American Imams                    </i>
                                            </p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>

                                <div className="testimonial-item">
                                    <div className="testimonial-content">
                                        {/* <div className="testimonial-img">
                                            <img src={testimonialImg_1} alt="" />
                                        </div> */}
                                        <div className="testimonial-content">
                                            <p>
                                                As salaamu alaikum
                                                <br />
                                                <br />

                                                <i><b>Seerah: Thematic Analysis, Inquiry and Reflection </b></i>was not only a pleasure to be a part of, IT IS A MUST TO TAKE!!! This course had benefit for those seeking spiritual edification. As an educator in the New York State Department of Education for 25 years, this course also adds value to the larger educational system as its focus on Analysis, Inquiry and Reflection are central components in authentic educational teaching, learning, and applications.
                                            </p>
                                            <p>
                                                <i class="font-weight-lighter">
                                                    Imam Talibudeen.<br />
                                                    <small className="text-black-50">
                                                        <span className="d-block">Alprentice Talibudeen McCutchen</span>
                                                        <span className="d-block">Asst Imam </span>
                                                        <span className="d-block">Masjid Sabur Inc.</span>
                                                        <span className="d-block">Bronx, NY</span>
                                                        <span className="d-block">History Teacher </span>
                                                        <span className="d-block">New Rochelle H.S </span>
                                                        <span className="d-block">New Rochelle, NY</span>
                                                    </small>                     </i>
                                            </p>
                                        </div>
                                        <div className="testimonial-icon">
                                            <i className="fi flaticon-right-quote"></i>
                                        </div>
                                    </div>
                                </div>




                            </Slider>
                        </div>
                    </div>
                </div>

            </div>




        );
    }
}

export default Testimonial;