
import React, {Fragment, Component, useRef} from 'react';
import emailjs from '@emailjs/browser';
import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';



const DemoModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
//////////////////////////CONTACT METHOD START
const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_sc0rekl', 'template_q698nbf', e.target, '8VeLM3Y26IwGbKqBV')
  .then((result) => {
        console.log(result.text);
        
         document.getElementById('success-msg-demo').append('Email Successfully Sent');

    }, (error) => {
        console.log(error.text);
    });
};


                return (
                    
                    <Fragment>
                        <Button
                            id='req-demo'
                            className={`btn ${buttonClass}`}
                            onClick={handleClickOpen}>
                            {button}
                            Request Demo
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            className="modalWrapper quickview-dialog"
                            maxWidth={maxWidth}
                        >
                            <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                            </DialogTitle>
                            <Grid className="modalBody modal-body">
                                    <div className="container">
                                        
                                        <div className='row p-3 '>
                                        <form ref={form} onSubmit={sendEmail} className="form text-white">
                                            <div class="container-fluid">
                                                <div id="gform_fields_2" class="row d-block d-md-flex">
                                                <div id="field_2_5" class="col-12 mb-5 col-md-4" data-js-reload="field_2_5">
                                                    <label class="gfield_label" for="input_2_5">Email <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_email">
                                                    <input name="input_5" id="input_2_5" type="text" class="form-control large" tabindex="49" placeholder="Your email"/>
                                                    </div>
                                                </div>
                                                <fieldset id="field_2_7" class="col-12 mb-5 col-md-4" data-js-reload="field_2_7">
                                                    <label class="">Are You Inquiring On Behalf Of A  <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_radio">
                                                    <div class="gfield_radio" id="input_2_7">
                                                        <div class="form-check">
                                                        <input class="form-check-input" name="input_7" type="radio" id="choice_2_7_0" tabindex="50" value="Individual"/>
                                                        <label for="choice_2_7_0" id="label_2_7_0" class="form-check-label">Individual</label>
                                                        </div>
                                                        <div class="form-check-inline">
                                                        <input class="form-check-input" name="input_7" type="radio" id="choice_2_7_1" tabindex="51" value="Non-profit organization"/>
                                                        <label for="choice_2_7_1" id="label_2_7_1" class="form-check-label">Non-profit organization</label>
                                                        </div>
                                                        <div class="form-check-inline">
                                                        <input class="form-check-input" name="input_7" type="radio" id="choice_2_7_2" tabindex="52" value="For-profit organization"/>
                                                        <label for="choice_2_7_2" id="label_2_7_2" class="form-check-label">For-profit organization</label>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </fieldset>
                                                <div id="field_2_8" class="col-12 mb-5 col-md-4" data-js-reload="field_2_8">
                                                    <label class="gfield_label" for="input_2_8">Name of individual or organization * <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_text">
                                                    <input name="input_8" id="input_2_8" type="text" class="form-control large" tabindex="53" placeholder="Your Answer" aria-required="true" aria-invalid="false" />
                                                    </div>
                                                </div>
                                                <div id="field_2_9" class="col-12 mb-5 col-md-4" data-js-reload="field_2_9">
                                                    <label class="gfield_label" for="input_2_9">Proposed workshop date* <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_date">
                                                    <input name="input_9" id="input_2_9" type="date" class="form-control" tabindex="54" placeholder="Select Date" aria-describedby="input_2_9_date_format" aria-invalid="false" aria-required="true" />
                                                    <span id="input_2_9_date_format" class="screen-reader-text">MM slash DD slash YYYY</span>
                                                    </div>
                                                </div>
                                                <fieldset id="field_2_10" class="col-12 mb-5 col-md-4" data-js-reload="field_2_10">
                                                    <label class="">Proposed workshop hours * <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="d-flex row">
                                                    <div class="col-3" id="input_2_10">
                                                        <input type="text" maxlength="2" name="input_10a" id="input_2_10_1" tabindex="55" placeholder="HH" aria-required="true" class="form-control"/>
                                                        <label class="hour_label screen-reader-text" for="input_2_10_1">Hours</label>
                                                    </div>
                                                    <div class="col-1">:</div>
                                                    <div class="col-3">
                                                        <input type="text" maxlength="2" name="input_10b" id="input_2_10_2" tabindex="56" placeholder="MM" aria-required="true"  class="form-control"/>
                                                        <label class="minute_label screen-reader-text" for="input_2_10_2">Minutes</label>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <select name="input_10c" id="input_2_10_3" tabindex="57" class="form-control">
                                                        <option value="am">AM</option>
                                                        <option value="pm">PM</option>
                                                        </select>
                                                        <label class="am_pm_label screen-reader-text" for="input_2_10_3">AM/PM</label>
                                                    </div>
                                                    </div>
                                                </fieldset>
                                                <div id="field_2_12" class="col-12 mb-5 col-md-4" data-js-reload="field_2_12">
                                                    <label class="gfield_label" for="input_2_12">Type of workshop <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_select">
                                                    <select name="input_12" id="input_2_12" class="form-control" tabindex="58" aria-required="true" aria-invalid="false">
                                                        <option value="Choose">Choose</option>
                                                        <option value="Conference">Conference</option>
                                                        <option value="Lecture">Lecture</option>
                                                        <option value="Filming location">Filming location</option>
                                                        <option value="Reception">Reception</option>
                                                        <option value="Performance">Performance</option>
                                                        <option value="Hybrid in person/virtual event">Hybrid in person/virtual workshop</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                    </div>
                                                </div>
                                                <div id="field_2_11" class="col-12 col-md-4" data-js-reload="field_2_11">
                                                    <label class="gfield_label" for="input_2_11">If you have date flexibility, please list your alternate date here <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_date">
                                                    <input name="input_11" id="input_2_11" type="date" class="form-control" tabindex="59" placeholder="Select Date" />
                                                    <span id="input_2_11_date_format" class="screen-reader-text">MM slash DD slash YYYY</span>
                                                    </div>
                                                </div>
                                                <div id="field_2_13" class="col-12 col-md-4" data-js-reload="field_2_13">
                                                    <label class="gfield_label" for="input_2_13">How many individuals do you anticipate at your workshop? * <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_text">
                                                    <input name="input_13" id="input_2_13" type="text" class="form-control large" tabindex="60" placeholder="Your Answer" aria-required="true" aria-invalid="false" />
                                                    </div>
                                                </div>
                                                <div id="field_2_17" class="col-12 col-md-4" data-js-reload="field_2_17">
                                                    <label class="gfield_label" for="input_2_17">Please use this space to share any additional information about your workshop. <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_textarea">
                                                    <textarea name="input_17" id="input_2_17" class="form-control small textarea" tabindex="61" placeholder="Your Answer" aria-required="true" aria-invalid="false" rows="10" cols="50"></textarea>
                                                    </div>
                                                </div>
                                                <fieldset  id="field_2_14" class="col-12 col-md-4" data-js-reload="field_2_14">
                                                    <label class="">Malcolm X Workshop Topics * <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_radio">
                                                    <div class="gfield_radio" id="input_2_14">
                                                    <select name="input_14" id="input_2_14" class="form-control" tabindex="58" aria-required="true" aria-invalid="false">
                                                    <option value="Choose">Choose</option>
                                                        <option value="Journey to Knowledge of Self">Journey to Knowledge of Self</option>
                                                        <option value="A Liberation of Consciousness">A Liberation of Consciousness</option>
                                                        <option value="Towards the Life we Were Meant to Lead">Towards the Life we Were Meant to Lead</option>
                                                        <option value="Towards Justice and Peace">Towards Justice and Peace</option>
                                                        <option value="Unlocking the Human Potential of Heart and Mind ">Unlocking the Human Potential of Heart and Mind </option>
                                                        <option value="Management and Governance in a Multi-Cultural World ">Management and Governance in a Multi-Cultural World </option>
                                                        <option value="Particulars of Experience vs Universal Experience ">Particulars of Experience vs Universal Experience </option>
                                                        <option value="Movement vs Organization Operating an Enterprise in the 21st Century">Movement vs Organization Operating an Enterprise in the 21st Century</option>
                                                    </select>
                                                    </div>
                                                    </div>
                                                </fieldset>
                                                <fieldset  id="field_2_15" class="col-12 col-md-4" data-js-reload="field_2_15">
                                                    <label class="">Institute * <span class="gfield_required">
                                                        <span class="gfield_required gfield_required_text">(Required)</span>
                                                    </span>
                                                    </label>
                                                    <div class="ginput_container ginput_container_radio">
                                                    <div class="" id="input_2_15">
                                                        <div class="">
                                                        <input class="form-check-inline" name="input_15" type="radio" id="choice_2_15_0" tabindex="64" value="Yes"/>
                                                        <label for="choice_2_15_0" id="label_2_15_0">School</label>
                                                        </div>
                                                        <div class="gchoice gchoice_2_15_1">
                                                        <input class="form-check-inline" name="input_15" type="radio" id="choice_2_15_1" tabindex="65" value="No"/>
                                                        <label for="choice_2_15_1" id="label_2_15_1">University</label>
                                                        </div>
                                                        <div class="gchoice gchoice_2_15_2">
                                                        <input class="form-check-inline gfield-choice-input" name="input_15" type="radio" id="choice_2_15_2" tabindex="66" value="Other"/>
                                                        <label for="choice_2_15_2" id="label_2_15_2">Other</label>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </fieldset>
                                                </div>
                                            </div>
                                            <div class="gform_footer top_label">
                                                <input type="submit" id="gform_submit_button_2" class="btn-block btn-lg btn-warning mt-4" tabindex="67" value="Submit"/>
                                            </div>
                                            <div id="success-msg-demo" className="bg-success col-12 mt-3  success-message text-center text-light" ></div>
                                            </form>              
                                        </div>
                                </div>
                                {/* <Counter/> */}
                            </Grid>
                        </Dialog>
                    </Fragment>
                )
            // }
}
export default DemoModal

