
import React, {Fragment} from 'react';


import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Counter from '../../Counter'

const AssadModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Read More
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                        <div className="container">
                            
                            <div className='row p-3 '>
                                <p className='text-light'>He Is an Accounting/Finance, Organizational Development, Change Management, Leadership and Human Capital Development Professional with experience in fourteen industries in developed and emerging markets.   </p>
                                <p className='text-light'>Assad Koshul grew up in Teaneck, NJ the first voluntarily integrated school system in the country.</p>
                                
                                <p className='text-light'> He has been a community activist since an early age and has worked in diverse groups for social justice causes.    His diverse professional experience and cross cultural competency equips him to create intellectual and emotional alignment in diverse environments and situations.  </p>
                                <p className='text-light'> His current work focuses on knowledge integration, concept and framework development for youth,  professionals, activists and spiritual leaders.   He also focuses on bridging the gap between academia and industry for young professionals as well as curriculum and workshop development for diverse audiences.  </p>
                                <p className='text-light'> He also trains religious leadership to address contemporary challenges through an integrated faith based approach.    </p>
                                <p className='text-light'> He is the Founder and a Facilitator of Malcolm X Visionary LLC </p>
                                <p className='text-light'> He is a graduate of Rutgers University, a Certified Public Accountant (CPA), Certified Scrum Master (CSM), PROSCI Change Management Certified and Certified Professional in Talent Development (CPTD)  </p>
                                                               
                            </div>
                    </div>
                    {/* <Counter/> */}
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default AssadModal

