import React from 'react'
import abimg from '../../images/image-9.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';


const Infrastructure = (props) => {

    return(
        <section id="infrastructure" className="tp-about-section border-0">
            <div className="container">
                <div className="row align-items-center">

                <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-wrap">
                        <div className="tp-about-img">
                            <img src={abimg} alt="" className=''/>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="tp-about-text">
                        {/* <div className="tp-about-icon">
                            <i className="fi flaticon-web-design"></i>
                        </div> */}
                        <div className="tp-about-icon-content ">
                            <div className='section-title section-title2'>
                            <h2>Learner - Participant Focused</h2>
                            </div>
                            <ul className=' pl-5 text-light'>
                                <li className="mb-3">Critical Thinking </li>
                                <li className="mb-3">Situational Awareness</li>
                                <li className="mb-3">Observation Power</li>
                                <li className="mb-3">Problem Solving Skills </li>
                                <li className="mb-3">Pattern Recognition</li>
                                <li className="mb-3">Blueprint + Mind Map for Productive Citizenship</li>
                                <li className="mb-3">Leverage Course to Impact Future Generations</li>
                                <li className="mb-3">Ongoing Impact Through Instructional Design</li>                               
                            </ul>

                            <h2>Course as a Tool For: </h2>
                            <div className='container'>
                                <div className='row'>
                                    <div className=' col'>
                                        <div className='text-white bg-orange p-4 text-center'>
                                            Self <br></br> Evaluation
                                        </div>
                                    </div>
                                    <div className=' col'>
                                        <div className='text-white bg-orange p-4 text-center'>
                                            Self <br></br> Realization
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='text-white bg-orange p-4 text-center mt-3 mt-md-0'>
                                            Self<br></br> Actualization
                                        </div>
                                    </div>
                                </div>
                            </div>
                               
                            

                           
                        </div>
                        </div>
                    </div>
                    
              
                </div>
            </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default Infrastructure;