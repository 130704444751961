
import React, {Fragment} from 'react';
import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Counter from '../../Counter'

const DavidModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Read More
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                        <div className="container">
                            
                            <div className='row p-3 '>
<p className='text-white'>Dr. David Akbar Gilliam was born in Youngstown, Ohio. He grew up there and in Chicago, Illinois. As a child, he first heard Islam from an aunt and uncle who joined the Nation of Islam under the leadership of Elijah Muhammad. As a young adult, he joined the Nation of Islam, still led by Elijah Muhammad. He continued in Islam  after the death of Elijah Muhammad, under the leadership of his son, Imam W. D. Mohamed, who succeeded his father as leader of the World Community of Islam. Shortly afterward, David Akbar returned from Youngstown to Chicago to take a position as staff writer and Spanish Editor of Bilalian News, the successor to Muhammad Speaks newspaper. Today, he and his wife make their home in Carol Stream, Illinois in the Metro Chicago area</p>
<p className='text-white'>Early in his career, David Akbar lived in Colombia, South America for one year, where he taught English at Universidad Javeriana in the capital city of Bogotá. In addition, he has traveled throughout Spain, North Africa and the Middle East, and has taught the courses “Islam in the United States” and “Explore Islamic Cultural Zones of Chicago” at DePaul University in Chicago </p>
<p className='text-white'>David Akbar earned his undergraduate degree in Hispanic Studies at Harvard University, and his master’s and doctoral degrees in Hispanic Studies at the University of Illinois at Chicago. He recently retired as Chair of the Department of Modern Languages and Associate Professor of Spanish at DePaul University in Chicago, IL. He served as an Emeritus Advisory Board Member of the university’s Center for African and Black Diaspora.</p>
                            </div>
                    </div>
                    {/* <Counter/> */}
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default DavidModal

