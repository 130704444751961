import React from 'react'
import abimg from '../../images/image-3.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';


const Aim = (props) => {

    return(
        <section id="aim" className="bg-transparent tp-about-section border-0">
            <div className="container">
            <div className="row align-items-center">
                    
            <div className="col-lg-7 col-md-12 col-12 align-self-center ">
                        
                        <p>Malcolm (1925-1965) was a man of many insights with a clear, revelatory vision that has been lost to cliched iterations of mainstream activism. He was a vocal advocate for black empowerment and a prominent figure during the civil rights movement. Malcolm’s spiritual journey, from his time in the Nation of Islam and conversion to orthodox Islam, elucidated the means of collective spiritual and material liberation for the African-American community. Topics including labor, race relations, identity and incarceration were masterfully distilled in his life and legacy. Malcolm's principled stance provides continued guidance to this day and emboldens us to meet contemporary challenges.</p>
                        <p>Through this curriculum, digital assets are leveraged to maximize learning in each lesson, and participants are continuously engaged to understand history as it relates to contemporary society. </p>
                   
        </div>

        
    <div className='col-lg-5 col-md-12 col-12'>
        <img src={abimg} alt="" className='img-fluid'/>

    </div>
                    </div>
                    </div>
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default Aim;