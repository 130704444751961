import React from 'react'
import abimg from '../../images/img-2.png'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';
import Faq from "react-faq-component";

const data = {
    title: "Browser all topics",
    rows: [
        {
            title: "Where will classes be conducted?",
            content: `<p>The classes are conducted in person live instructions and also live by zoom. In the future we will have asynchronized learning but at the moment all classes are live in person via zoom.</p>`,
        },
        {
            title: "How many classes per week?",
            content:
                "<p>Ranges from one class per week to 4 classes per week depending on the participant and plan selection.</p>",
        },
        {
            title: "What is cancellation policy?",
            content: `<p>Monthly enrolled can be cancelled anytime.</p>`,
        },
        {
            title: "Who has reviewed the curriculum?",
            content: <p> The series itself has been approved by numerous scholars which was produced by Middle East Corporation 1 (MBC1)
            <ul>
                <li style={{listStyle: "inside"}}>Akram Zia Omari</li>
                <li style={{listStyle: "inside"}}>Salman al-Awda</li>
                <li style={{listStyle: "inside"}}>Abdul Wahab Turairi</li>
                <li style={{listStyle: "inside"}}>Ali al-Sallabi</li>
                <li style={{listStyle: "inside"}}>Saad Al-Otaibi[34]</li>
            </ul>
            The curriculum we designed based on the TV series has been approved by Imam Zaid Shakir and Muhammad S Eissa Phd.</p>,
        },
        {
            title: "What is the technology platform utilized for the classes?",
            content: <p>A Learning Management System is integrated with our website and all students are assigned User ID.</p>,
        },
        {
            title: "What is the pricing model?",
            content: <p>Pricing plans range from $ 75 per month per individual to $3,000 per school.</p>,
        },
        {
            title: "Do you have assessments as part of the curriculum?",
            content: <p> We have Multiple Choice tests and quizzes as well as essay questions for students as part of the school curriculum. Multiple choice questions are graded within our LMS to facilitate implementation by the teacher.</p>,
        },
        {
            title: "Do you have trainings for teachers who want to adopt the curriculum?",
            content: <p>Yes we have designed a training program of up to 40 hours that prepares the teacher to teach the curriculum. This includes training on the LMS, Key Concepts, Structured Inquiry Etc.</p>,
        },
        {
            title: "What is the length of the curriculum?",
            content: <p>Please see the Criterion Plans page.</p>,
        },
        {
            title: "Can you customize lessons plans",
            content: <p>Yes based on client/institution needs we can consider customization.
            </p>,
        },
        

    ],
};

const styles = {
    bgColor: '#202020',
    titleTextColor: "white",
     rowTitleColor: "white",
    rowContentColor: 'white',
    arrowColor: "white",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};


const Faqs = (props) => {

    return(
        <section id="faqs" className=" tp-about-section  bg-transparent">
            <div className="container">
                    
                    <div className="col-12 text-center">
                       
                      
                        <div className='section-title section-title2'>
                        <h2  className='bg-repeat'>FAQ</h2>
                            </div>

                   
                    </div>
                    <div className='row'>
                    <div className='col'>
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                        
                    </div>
                    <div className="white_svg">
                    <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                        <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                    </svg>
                </div>
                    </div>
           
        </section>
    )
}

export default Faqs;