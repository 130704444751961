import React, {Fragment} from 'react';
import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Counter from '../../Counter'

const IlyasahShabazzBio = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Read More
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                        <div className="container">
                            
                            <div className='row p-3 '>
                              <p className='text-light'>                                                                
                                Professor Ilyasah Shabazz promotes higher education for at-risk youth, interfaith dialogue to build bridges between cultures for young leaders of the world, and she participates in international humanitarian delegations. She served as a member of the U.S. Delegation that accompanied President Bill Clinton to South Africa to commemorate the election of President Nelson Mandela and the Education & Economic Development initiatives.
                              </p>
                                
                              <p className='text-light'>
                                She was a member of the U.S. Interfaith Leadership Delegation to Mali, West Africa with Malaria No More, and she received a personal letter of acknowledgment for preserving her “father’s proud legacy by working to secure equality in our time and for generations to come,” from President Barack Obama.
                                </p>
                              
                              <p className='text-light'>
                                She is an inspirational role model and advocate for “youth” and “women and girl” empowerment. Her lifework is devoted to helping others find inner strength and purpose. While she is frequently asked to speak about the Legacy of Malcolm X, she shares that it is her mother, Dr. Betty Shabazz’s wisdom, courage and compassion that guide her
                                </p>
                              
                              <p className='text-light'>
                                More than six years experience as college professor More than twenty years experience as administrator and implementer of cultural and community outreach initiatives, serving diverse populations Key advisor to public and private organizations, developing diversity and community-focused programs that align with business, academic and organizational goals; Author, artist, mentor, educator, motivational speaker and citizen of the world, connecting and activating networks and resources to create measurably positive outcomes. Published five multiple award-winning publications with outstanding novelists, currently working on the next…
                                </p>
                                
                            </div>
                    </div>
                    {/* <Counter/> */}
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default IlyasahShabazzBio;

