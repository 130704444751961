import React from 'react'
import abimg from '../../images/image-6.jpg'
import sign from '../../images/signeture.png'
import DefaultModal from '../AboutModal'
import '../../css/custom.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import book1 from '../../images/books/Autobiography of Malcolm X.jpg';
import book2 from '../../images/books/Dead Are Arising.jpg';
import book3 from '../../images/books/Growing Up X.jpg';
import book4 from '../../images/books/On the Side of My People.jpg';
import book5 from '../../images/books/The Awakening of Malcolm X.jpg';
import movie1 from '../../images/movies/Blood-Brothers.jpg';
import movie2 from '../../images/movies/Malcolm X – Make it Plain.png';
import movie3 from '../../images/movies/Malcolm X 1972 Documentary.jpg';
import movie4 from '../../images/movies/Malcolm X Movie – Spike Lee.jpg';
import movie5 from '../../images/movies/Malik – Malcolm X Documentary Film.jpg';


const LearnerInfra = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        fade: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        
       
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section id="LearnerInfra" className="tp-about-section bg-transparent">
            <div className="container">
            <div className="col-l2">
                        <div className="section-title section-title2 text-center">
                            <span>Sources</span>
                            <h2>Books / Movies</h2>
                        </div>
                    </div>
                <div className="align-items-center d-flex justify-content-center px-5 row text-center">
                    
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={book1} alt="" className='img-fluid'/>
                                                <span class="text-muted">Book</span>
                                            </div>
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={book2} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Book</span>
                                            </div>
                                            <div className=" px-2 col-6 col-md-2">
                                                <img src={book3} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Book</span>
                                            </div>
                                            <div className=" px-2 col-6 col-md-2">
                                                <img src={book4} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Book</span>
                                            </div>
                                            <div className=" px-2 col-6 col-md-2">
                                                <img src={book5} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Book</span>
                                            </div>
                                            <div className=" px-2 col-6 col-md-2">
                                                <img src={movie1} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Movie</span>
                                            </div>
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={movie2} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Movie</span>
                                            </div>
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={movie3} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Movie</span>
                                            </div>
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={movie4} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Movie</span>
                                            </div>
                                            <div className="px-2 col-6 col-md-2">
                                                <img src={movie5} alt=""  className='img-fluid'/>
                                                <span class="text-muted">Movie</span>
                                            </div>

                                        </div>
                                    </div>

                   
  
            <div className="white_svg svg_white">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
        </section>
    )
}

export default LearnerInfra;