import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class Quotes extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            rows: 2,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div id="testimonial" className="testimonial-area">
                <div className="container">
                <div className="col-l2">
                    <div className="section-title section-title2 text-center">
                        <span>Malcolm X</span>
                        <h2>Quotes</h2>
                    </div>
                </div>
                    <div className="testimonial-active">
                        <div className="testimonial-wrap">
                            <Slider {...settings}>
                            <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        If you stick a knife in my back nine inches and pull it out six inches, there’s no progress. If you pull it all the way out, that’s not progress. The progress is healing the wound that the blow made.                                         </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        You can’t separate peace from freedom because no one can be at peace unless he has his freedom. 

                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        There is no better than adversity. Every defeat, every heartbreak, every loss, contains its own seed, its own lesson on how to improve your performance next time.
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        The common goal of 22 million Afro-Americans is respect as human beings, the God-given right to be a human being. Our common goal is to obtain the human rights that America has been denying us. We can never get civil rights in America until our human rights are first restored. We will never be as recognized as citizens there until we are first recognized as humans. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        Hence I have no mercy or compassion in me for a society that will crush people, and then penalize them for not being able to stand up under the weight.
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        I believe in the brotherhood of man, of all men, but I don’t believe in wasting brotherhood on anyone who doesn’t want to practice it with me. Brotherhood is a two-way street.
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        Fools try to ignore facts, but wise men must face facts to remain wise. Fools refuse to change from their old silly ways and beliefs, but the mental flexibility of the wise man permits him to keep an open mind and enables him to readjust himself whenever it becomes necessary for change. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        By any means necessary. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        If you aren’t careful, the newspapers will have you hating the people who are being oppressed, and loving the people who are doing the oppressing. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        Children have a lesson adults should learn, to not be ashamed of failing, but to get up and try again. Most of us [adults] are … so cautious …and therefore so … rigid and afraid that it is why so many humans fail. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        So early in life, I had learned that if you want something, you had better make some noise.
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        My alma mater was books, a good library … I could spend the rest of my life reading, just satisfying my curiosity. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        To me, the thing that is worse than death is betrayal. You see, I could conceive death, but I could not conceive betrayal. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        You’re not supposed to be so blind with patriotism that you can’t face reality. Wrong is wrong, no matter who does it or who says it. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        To have once been a criminal is no disgrace. To remain a criminal is the disgrace. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        If you’re not ready to die for it, take the word “freedom” out of your vocabulary. 
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div className="testimonial-item mb-5">
                                    <div className="testimonial-content">
                                        <div className="testimonial-content">
                                        <p> 
                                        The black man in the ghettoes, for instance, has to start correcting his own material, his own moral and spiritual defects, and his own evils. The black man needs to start his own program to rid drunkenness, drug addiction, and prostitution. The black man in America has to lift up his own sense of values.
                                        </p>
                                    <p>
                                       
                                    </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </Slider>
                        </div>
                    </div>
                </div>
               
            </div>




        );
    }
}

export default Quotes;