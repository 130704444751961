import React, { useState } from 'react';
import GoalsSingle from '../ServiceSingle'
import { Button } from '@material-ui/core'
import s1 from '../../images/service-single/web-design/img-1.jpg'
import s2 from '../../images/service-single/web-design/img-2.jpg'
import s3 from '../../images/service-single/web-design/img-3.jpg'

import d1 from '../../images/service-single/development/img-1.jpg'
import d2 from '../../images/service-single/development/img-2.jpg'
import d3 from '../../images/service-single/development/img-3.jpg'

import c1 from '../../images/service-single/creative/img-1.jpg'
import c2 from '../../images/service-single/creative/img-2.jpg'
import c3 from '../../images/service-single/creative/img-3.jpg'

import r1 from '../../images/service-single/responsive/img-1.jpg'
import r2 from '../../images/service-single/responsive/img-2.jpg'
import r3 from '../../images/service-single/responsive/img-3.jpg'

import b1 from '../../images/service-single/branding/img-1.jpg'
import b2 from '../../images/service-single/branding/img-2.jpg'
import b3 from '../../images/service-single/branding/img-3.jpg'

import sp1 from '../../images/service-single/support/img-1.jpg'
import sp2 from '../../images/service-single/support/img-2.jpg'
import sp3 from '../../images/service-single/support/img-3.jpg'

import icon_1 from '../../images/school.png'
import icon_2 from '../../images/001-communities.png'
import icon_3 from '../../images/002-education.png'
import icon_4 from '../../images/003-youth-day.png'
import icon_5 from '../../images/004-prisoner.png'
import icon_6 from '../../images/009-pray.png'
import icon_7 from '../../images/006-graduating-student.png'
import icon_8 from '../../images/002-women.png'
import icon_9 from '../../images/009-moslem.png'
import icon_10 from '../../images/team.png'


const Design = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state,setState] = useState({
    })

    const handleClickOpen = (item) =>{
        setOpen(true);
        setState(item)
    }
    const goals = [
        {
            Id:"1",
            sIcon:icon_1,
            heading:"Full Time Public/Private Schools",
            Simg1:s1,
            Simg2:s2,
            Simg3:s3,
            des:'Expose young minds to the incredible story of Malcolm’s life during their formative years, when it can be of maximum benefit'
        },
        {
            Id:"2",
            sIcon:icon_2,
            heading:"Community Activist",
            Simg1:d1,
            Simg2:d2,
            Simg3:d3,
            des:'Offer community activists the intellectual rigor needed to fortify their praxis'
            
        },
        {
            Id:"3",
            sIcon:icon_3,
            heading:"Teachers/Facilitators",
            Simg1:c1,
            Simg2:c2,
            Simg3:c3,
            des:'Equip teachers to connect with and positively impact their students while covering salient topics '
        },
        {
            Id:"4",
            sIcon:icon_4,
            heading:"Youth Groups",
            Simg1:r1,
            Simg2:r2,
            Simg3:r3,
            des:'Enrich young people with relevant knowledge to navigate contemporary challenges'
        },
        {
            Id:"5",
            sIcon:icon_5,
            heading:"Incarcerated",
            Simg1:b1,
            Simg2:b2,
            Simg3:b3,
            des:'Aid the rehabilitative process by sharing restorative and regenerative knowledge related to Malcolms time in prison'
        },
        {
            Id:"6",
            sIcon:icon_6,
            heading:"Chaplains",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Add value to the faith-based practice of providing spiritual care to those who may benefit from it'
        },
        {
            Id:"7",
            sIcon:icon_7,
            heading:"College Students",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Understand how Malcolm’s story encapsulates themes of society + justice, as they are pertinent to receiving a college education '
        },
        
        {
            Id:"8",
            sIcon:icon_8,
            heading:"Women’s Organizations",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Learn about how Malcolm championed the rights of the marginalized, specifically Black women '
        },
        
        {
            Id:"9",
            sIcon:icon_9,
            heading:"Spiritual Leaders ",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Teach spiritual leaders how to incorporate the visionary work of Malcolm X in a manner that resonates with their congregation'
        },
        {
            Id:"10",
            sIcon:icon_10,
            heading:"Business Managers and Executives",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Provide a necessary forum for discussion on how to uphold equity in the workplace'
        },
    ]

    return (
        <div id="goals" className="service-area section-padding">
            <div className="borderd"></div>
            <div className="container">
                <div className="col-l2">
                    <div className="section-title section-title2 text-center">
                        {/* <span>Curriculum</span> */}
                        <h2 className='form-check-inline'>  Curriculum Designed For:</h2>
                    </div>
                </div>
                <div className="items-goals justify-content-center row">
                    {goals.map((serv, srv) => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={srv}>
                            <div className="service-section">
                                <div className="services-wrapper">
                                    <div className="services-icon-wrapper mb-4">
                                        <div className="service-dot">
                                            <div className="dots"></div>
                                            <div className="dots2"></div>
                                        </div>
                                        <img src={serv.sIcon}/>
                                        
                                    </div>
                                    <div className="service-content">
                                        <h3 className='text-white'>{serv.heading}</h3>
                                        <p>{serv.des}</p>
                                        {/* <Button
                                            className="btn"
                                            onClick={()=> handleClickOpen(serv)}>
                                            Read More
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                </div>
            </div>
            <div className="white_svg">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
            <GoalsSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/>
        </div>
    );
}
export default Design;