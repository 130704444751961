
import React, {Fragment} from 'react';


import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Counter from '../../Counter'

const MalikModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Read More
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                        <div className="container">
                            
                            <div className='row p-3 d-block'>
                                <p className='text-light'>Aisha H.L. al-Adawiya is the founder and President of Women in Islam, Inc., an organization of Muslim women which focuses on human rights and social justice. Ms. al-Adawiya also represents Muslim women’s Non-Governmental Organizations at United Nations forums, as well as coordinating Islamic input for the Preservation of the Black Religious Heritage Documentation Project of the Schomburg Center for Research in Black Culture. She organizes and participates in conferences and other forums on Islam, Gender Equity, Conflict Resolution, Cross-Cultural Understanding, and Peace Building. Ms. al-Adawiya is consultant to numerous interfaith organizations and documentary projects on the Muslim American experience, while also serving on the boards of numerous organizations related to the interests of the global Islamic community.

</p>

                                
                                
                            </div>
                    </div>
                    {/* <Counter/> */}
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default MalikModal;

