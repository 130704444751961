import React, { useState } from 'react';
import ServiceSingle from '../ServiceSingle'
import { Button } from '@material-ui/core'
import s1 from '../../images/service-single/web-design/img-1.jpg'
import s2 from '../../images/service-single/web-design/img-2.jpg'
import s3 from '../../images/service-single/web-design/img-3.jpg'

import d1 from '../../images/service-single/development/img-1.jpg'
import d2 from '../../images/service-single/development/img-2.jpg'
import d3 from '../../images/service-single/development/img-3.jpg'

import c1 from '../../images/service-single/creative/img-1.jpg'
import c2 from '../../images/service-single/creative/img-2.jpg'
import c3 from '../../images/service-single/creative/img-3.jpg'

import r1 from '../../images/service-single/responsive/img-1.jpg'
import r2 from '../../images/service-single/responsive/img-2.jpg'
import r3 from '../../images/service-single/responsive/img-3.jpg'

import b1 from '../../images/service-single/branding/img-1.jpg'
import b2 from '../../images/service-single/branding/img-2.jpg'
import b3 from '../../images/service-single/branding/img-3.jpg'

import sp1 from '../../images/service-single/support/img-1.jpg'
import sp2 from '../../images/service-single/support/img-2.jpg'
import sp3 from '../../images/service-single/support/img-3.jpg'

import sign from '../../images/image-11.jpg'


const Instruction = () => {

    const [open, setOpen] = React.useState(false);

    function handleClose() {
        setOpen(false);
    }

    const [state,setState] = useState({
    })

    const handleClickOpen = (item) =>{
        setOpen(true);
        setState(item)
    }
    const service = [
        {
            Id:"1",
            sIcon:"fi flaticon-web-design",
            heading:"minister At NO",
            Simg1:s1,
            Simg2:s2,
            Simg3:s3,
            des:'In December 1953, a little more than a year after he was paroled from prison, Malcolm was named the minister at the NOIs Boston mosque, Temple No. 11. The following year he also became the minister at Temple No. 12 (Philadelphia) and Temple No. 7 (New York).'
        },
        {
            Id:"2",
            sIcon:"fi flaticon-laptop",
            heading:"NOI NEWSPAPER",
            Simg1:d1,
            Simg2:d2,
            Simg3:d3,
            des:'Muhammad Speaks, the NOI newspaper, was founded by Malcolm in 1957.'
            
        },
        {
            Id:"3",
            sIcon:"fi flaticon-paint-palette",
            heading:"Debates",
            Simg1:c1,
            Simg2:c2,
            Simg3:c3,
            des:'Beginning in the 1960s, Malcolm was invited to participate in numerous debates, including forums on radio stations (Los Angeles, New York, Washington), television programs (“Open Mind,” “The Mike Wallace News Program”) and universities (Harvard Law School, Howard University, Columbia University).'
        },
        {
            Id:"4",
            sIcon:"fi flaticon-smartphone",
            heading:"Voguish Speaker",
            Simg1:r1,
            Simg2:r2,
            Simg3:r3,
            des:'In 1963, the New York Times reported that Malcolm X was the second most sought-after speaker in the United States.'
        },
        {
            Id:"5",
            sIcon:"fi flaticon-verified",
            heading:"Civil right events",
            Simg1:b1,
            Simg2:b2,
            Simg3:b3,
            des:'On June 29, 1963 Malcolm lead the Unity Rally in Harlem. It was one of the nations largest civil rights events.'
        },
        {
            Id:"6",
            sIcon:"fi flaticon-operator",
            heading:"Malcolm’s Autobiography",
            Simg1:sp1,
            Simg2:sp2,
            Simg3:sp3,
            des:'Malcolm’s autobiography, which he worked on for two years with writer Alex Haley, was published in November 1965.'
        },
    ]

    return (
        <div id="service" className="service-area section-padding">
            <div className="borderd"></div>
            <div className="container">
                
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="tp-about-text">
                        
                        <div className="tp-about-icon-content">
                        <div className='section-title section-title2'>
                        {/* <span>Malcolm X</span> */}
                        <h2 className='m-0'>Instructional Design</h2></div>
                        <p className='h3 mb-5'>
                        Instructional Design is Inquiry Based and Focuses on :   
                       </p>
                            <ul class=" pl-5 h4 text-light">
                                <li class="mb-3">Active Learning not Passive Learning</li>
                                <li class="mb-3">Thematic Analysis</li>
                                <li class="mb-3">Probing Questions</li>
                                <li class="mb-3">Exercises (Individual + Group)</li>
                                <li class="mb-3">Mind Mapping</li>
                                <li class="mb-3">Action Items</li>
                                <li class="mb-3">Reflective Questions</li>
                                <li class="mb-3">Visual Proverbs to Maximize Comprehension, Understanding + Contextualization</li>
                            </ul>
                    </div>
                    </div>
                    </div>

                    <div className='col-md-4'>
                        <img src={sign} alt="" className='signature mb-2'/>
                    </div>
                </div>
            </div>
            <div className="white_svg">
                <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
                </svg>
            </div>
            <ServiceSingle open={open} onClose={handleClose} title={state.heading} doc={state.doc} image1={state.Simg1} image2={state.Simg2} image3={state.Simg3}/>
        </div>
    );
}
export default Instruction;