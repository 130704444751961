
import React, {Fragment} from 'react';


import { Button, Dialog, Grid, } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Counter from '../../Counter'

const ZaidModal = ({ maxWidth, button, buttonClass }) => {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const styles = (theme) => ({
        root: {
          margin: 0,
          padding: theme.spacing(2),
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <i className="fa fa-close"></i>
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });


    return (
        <Fragment>
            <Button
                className={`btn ${buttonClass}`}
                onClick={handleClickOpen}>
                {button}
                Read More
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                className="modalWrapper quickview-dialog"
                maxWidth={maxWidth}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                </DialogTitle>
                <Grid className="modalBody modal-body">
                        <div className="container">
                            
                            <div className='row p-3 text-white'>
<p className='text-white'>Imam Zaid Shakir was born in Berkeley, California, in 1956, one of seven siblings. He was raised in Atlanta, Georgia and New Britain, Connecticut. During his school years he was active in sports, focusing on football and track. He converted to Islam in 1977 while serving in the United States Air Force.</p>
<p className='text-white'>He is the co-founder of Zaytuna College, the country’s first accredited Muslim Liberal Arts College.</p>
<p className='text-white'>Even before converting to Islam, he was concerned about communal change, leading him to briefly embrace communism and working on the cases of Joan Little and the Wilmington Ten. His desire to effect meaningful change after converting led to his assisting in founding three masjids in New Brunswick, New Jersey, New Haven, Connecticut, and Oakland, California, as well as other organizations such as the Connecticut Muslim Coordinating Committee and the Tri-State Muslim Educational Initiative.</p>
<p className='text-white'>Imam Zaid is a spiritual philosopher, aligns different intellectual traditions and creates intellectual frameworks, with which to harmonize individuals, families, and societies. He is a visionary leader who is a change agent who places emphasis on spiritual, mental and physical health.</p>
<p className='text-white'>He is a graduate of the US Air Force Supervisory Training Program, ESL Teacher’s Training Program, American University, and Rutgers University Graduate School, where he obtained a Masters Degree (MA) in Political Science.</p>
<p className='text-white'>Imam Zaid is passionate about education and writing, having authored four books and a plethora of articles dealing with various subjects. He is a cofounder of Zaytuna College, the country’s first accredited Muslim College liberal Arts college.</p>
<p className='text-white'>He is a signatory of a declaration in support of the Paris Climate Agreement under the United Nations Framework Convention on Climate Change and authored the Muslim response to Pope Francis’ Encyclical on Climate Change, Laudato si’. He is an urban gardener as well as an artist and poet. His goal is to see a strong and viable Muslim community, whose roots are firmly planted in the soil tilled by the African Muslims whose sacrifices were so instrumental in building this country, and whose branches provide shade for the entire community in all of its diversity.</p>
                            </div>
                    </div>
                    {/* <Counter/> */}
                </Grid>
            </Dialog>
        </Fragment>
    );
}
export default ZaidModal

